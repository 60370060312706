import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useSearchParams, useRouter } from 'src/routes/hook';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function JwtLoginCustomerView() {
    const { customerLogin } = useAuthContext();

    const [errorMsg, setErrorMsg] = useState('');

    const router = useRouter();

    const password = useBoolean();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: '', // rsaiprasad4@gmail.com
        password: '',  // 123456789
    };

    const [formValues, setFormValues] = useState(defaultValues);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            // await LoginSchema.validate(formValues, { abortEarly: false });
            await customerLogin?.(formValues.email, formValues.password);
            setIsSubmitting(false);
            console.log('done');

            router.push('/dashboard/customerDetails');
        } catch (error) {
            setIsSubmitting(false);
            if (error.name === 'ValidationError') {
                // Handle validation errors
                setErrorMsg(error.errors.join(' '));
            } else {
                // Handle other types of errors
                enqueueSnackbar(error.error, { variant: 'error' });
            }
        }
    };


    const renderHead = (
        <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography variant="h4">Sign in to ATROS Customer Panel</Typography>
        </Stack>
    );

    const renderForm = (
        <Stack spacing={2.5}>
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

            <TextField
                name="email"
                label="Email address"
                value={formValues.email}
                onChange={handleChange}
                error={Boolean(LoginSchema.fields.email && LoginSchema.fields.email.errors)}
                helperText={
                    LoginSchema.fields.email &&
                    LoginSchema.fields.email.errors &&
                    LoginSchema.fields.email.errors[0]
                }
            />
            <TextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                value={formValues.password}
                onChange={handleChange}
                error={Boolean(LoginSchema.fields.password && LoginSchema.fields.password.errors)}
                helperText={
                    LoginSchema.fields.password &&
                    LoginSchema.fields.password.errors &&
                    LoginSchema.fields.password.errors[0]
                }
            />
            <LoadingButton
                fullWidth
                name="loginButton"
                color="inherit"
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit}
            >
                Login
            </LoadingButton>
        </Stack>
    );

    return (
        <>
            {renderHead}
            {renderForm}
        </>
    );
}
